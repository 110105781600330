// JQuery Extensions
$.fn.validateOn = function (
  eventType,
  validateFunc,
  afterSuccessfulValidation,
  options
) {
  $(this).on(eventType, function (event) {
    // NOTE: by calling the validation function this way, we're ensuring "this" inside the function
    // is the same as "this" outside the function, i.e. it references the form being validated.
    var results = validateFunc.call(this, event);

    // supports a return value of either a Validator, or an array of validations
    var valid =
      typeof results.isValid === 'function'
        ? results.isValid()
        : Validate.isValid(results);

    if (!valid) {
      if (options === undefined || options.scroll !== false) {
        Validate.scrollToFirstError();
      }
      return false;
    }

    // if defined, call the afterValidation callback
    if (afterSuccessfulValidation) {
      return afterSuccessfulValidation.call(this, event);
    }
  });
};

$.fn.offOn = function (event, listener) {
  this.off(event).on(event, listener);
};
